import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;
import _index5 from "../../toDate/index.js";
import _index6 from "../startOfUTCISOWeek/index.js";
import _index7 from "../startOfUTCISOWeekYear/index.js";
import _index8 from "../requiredArgs/index.js";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getUTCISOWeek;
var _index = _interopRequireDefault(_index5);
var _index2 = _interopRequireDefault(_index6);
var _index3 = _interopRequireDefault(_index7);
var _index4 = _interopRequireDefault(_index8);
var MILLISECONDS_IN_WEEK = 604800000;
function getUTCISOWeek(dirtyDate) {
  (0, _index4.default)(1, arguments);
  var date = (0, _index.default)(dirtyDate);
  var diff = (0, _index2.default)(date).getTime() - (0, _index3.default)(date).getTime();

  // Round the number of days to the nearest integer
  // because the number of milliseconds in a week is not constant
  // (e.g. it's different in the week of the daylight saving time clock shift)
  return Math.round(diff / MILLISECONDS_IN_WEEK) + 1;
}
exports = exports.default;
export default exports;